<template>
    <div class="tsa-Dialog" v-show="show">
        <div class="diaContent" :style="{width: width ? width + 'px' : '840px'}">
            <div class="title" v-if="title">
                <p>{{ title }} <img class="close" src="@/assets/images/close.png" @click="close" alt=""></p>
                <slot name="headerTip" />
            </div>
            <div class="area">
                <slot />
            </div>
            <div class="btnGroup" v-if="cancelBtn || confirmBtn">
                <slot name="footerTip" />
                <el-button @click="cancel" v-if="cancelBtn">{{ cancelText }}</el-button>
                <el-button :disabled="disabled" :loading="disabled" @click="confirm" v-if="confirmBtn" type="primary">{{ confirmText }}</el-button>
            </div>
        </div>
        <div class="mask"></div>
    </div>
</template>
<script>
export default {
    name: 'tsa-Dialog',
    props: {
        value: {  //父组件使用v-model 默认vue会给子组件传一个名称为value的props
            type: Boolean,
            default: false
        },
        confirmBtn: {
            type: Boolean,
            default: true
        },
        cancelBtn: {
            type: Boolean,
            default: true
        },
        confirmType: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        confirmText: {
            type: String,
            default: '确认'
        },
        width: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            show: false,
        }
    },
    watch: {
        value (val) {
            this.show = val;
        },
    },
  computed:{
    confirmCancelToast(){
      return this.$t('confirmCancel')
    },
  },
    methods: {
        confirm () {
            this.$emit('on-confirm')
        },
        cancel () {
            this.$emit('input', false); // 需要关闭组件时，触发input事件 设置父组件 v-model 的 show 为false
            this.$emit('on-cancel')
        },
        close () {
            this.$emit('on-close')
            this.$emit('input', false); // 需要关闭组件时，触发input事件 设置父组件 v-model 的 show 为false
        },
    }
}
</script>
<style lang="scss" scoped>
    .tsa-Dialog {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        .diaContent {
            padding-bottom: 10px;
            background: #fff;
            z-index: 2;
            position: relative;
            border-radius: 4px;
            width: 840px;
            overflow: hidden;
            .title {
                font-size: 16px;
                padding: 24px 0;
                font-weight: bold;
                position: relative;
                .close {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                }
                p {
                    margin-bottom: 0;
                    position: relative;
                }
            }
            .area {
                padding-bottom: 10px;
            }
            .btnGroup {
                display: flex;
                justify-content: flex-end;
                padding-right:20px
            }
        }
        .mask {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba($color: #000000, $alpha: 0.7);
        }
    }
</style>