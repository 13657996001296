<template>
    <div class="wrap aigc-wrap">
        <div class="page-main">
            <div class="sec-box">
                <div class="section">
                    <span>产品说明</span>
                    <p><b>AIGC PAS (AIGC Provenance and Authentication Service) </b>平台是联合信任推出的人工智能生成内容(AIGC)的溯源与认证平台。平台的AIGC创作过程认证功能为AIGC生成过程进行全程存证，让人的创作过程和内容得到见证。为解决AIGC发展过程中面临的深度伪造、版权纠纷、信任危机以及平台合规等提供证据。</p>
                </div>
                <div class="section">
                    <span>收费标准</span>
                    <strong class="gobuy" @click="gobuy">购买时间戳 <i class="el-icon-ali-buytimestamp"></i></strong>
                    <ul>
                        <li>
                            <b>认证费：</b><p>每次认证扣减1个时间戳，录制时长不限。</p>
                        </li>
                        <li>
                            <b>存储费：</b><p>将根据存证文件大小以及存储期限进行计费 (与知识产权保护平台的存证<a href="/time/Rules" target="_blank">计费规则</a>相同)</p>
                        </li>
                    </ul>
                </div>
            </div>


            <div class="steps-wrap">
                <div class="steps">
                    <div 
                        :class="stepsActive==item.index?'step active' : stepsActive>item.index ?'passStep step' : 'step'"
                        v-for="item in steps" :key="item.index"
                    >
                        <div class="title">
                            <b v-if="item.index<stepsActive"><i class="el-icon-check"></i></b>
                            <b v-else>{{ item.index }}</b>
                            <span>{{ item.title }}</span>
                        </div>
                        <div class="dividLine">
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="page-one" v-show="stepsActive==1">
                    <el-form
                        class="form-element-ui"
                        ref="form1"
                        label-width="100px"
                        label-position="right"
                        :rules="rules"
                        :model="form1"
                    >
                        <el-form-item prop="opusName">
                            <label slot="label">作品名称</label>
                            <el-input v-model="form1.opusName" maxlength="70" show-word-limit placeholder="请输入你的AI作品名称，如“xx作品_创作过程认证”"></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="meadia-wrap">
                        <MediaRecorder ref="MediaRecorder" @on-success="recordingSuccess" @on-error="recordingError" @on-start="getOpenGuide" @on-reset="hadleReset"  />
                    </div>
                    <div class="btns" v-if="hasVideo">
                        <span class="prev" @click="handlRecording">重新录制</span>
                        <span class="next" @click="next(2,'form1')">下一步</span>
                    </div>
                </div>
                <div class="page-two" v-show="stepsActive==2||stepsActive==3">
                    <div class="title"><b></b>{{ applyTimeText[5].ApplyInfo.title1 }}</div>
                    <div class="personInfo">
                        <el-form ref="form2" label-width="135px" label-position="left">
                            <el-form-item>
                                <label slot="label"><b class="red">*</b>{{ applyTimeText[5].ApplyInfo.title2 }}</label>
                                <el-input
                                    :disabled="attestationInfo.identificationStatus == 1 || userInfo.userType == 'AUTHORIZATION_ACCOUNT'"
                                    v-model="information.applyName"
                                    class="myinput"
                                    :placeholder="$t('applySteps.applicantStepsText') "
                                >
                                </el-input>
                                <span v-if="$store.state.userInfo.userInfo.userType !=='AUTHORIZATION_ACCOUNT'">
                                    <span 
                                        class="goRealName" 
                                        v-if="attestationInfo.identificationStatus == 2"
                                        @click="goReal"
                                    >
                                            {{ applyTimeText[5].ApplyInfo.title14 }}
                                    </span>
                                    <span 
                                        class="goRealName" 
                                        style="color:#FF7800"
                                        v-else
                                    >
                                        {{ applyTimeText[5].ApplyInfo.title5 }}
                                    </span>
                                </span>
                            </el-form-item>
                            <el-form-item>
                                <label slot="label">
                                    <b class="red" v-if="showNameType!==2">*</b>
                                    {{ applyTimeText[5].ApplyInfo.title3 }}
                                </label>
                                <el-select
                                    :disabled="isdisabled"
                                    v-model="information.applyIdType"
                                    :placeholder="$t('applySteps.selectStepsText') "
                                    class="myinput"
                                >
                                    <el-option 
                                        v-for="item in papersType" 
                                        :key="item.value" 
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <label slot="label">
                                    <b class="red" v-if="showNameType!==2">*</b>
                                    {{ applyTimeText[5].ApplyInfo.title4 }}
                                </label>
                                <el-input 
                                    :disabled="isdisabled" 
                                    v-model="information.applyIdNumber" 
                                    class="myinput"
                                    :placeholder="$t('applySteps.idNumberStepsText')"
                                >
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="title"><b></b>{{ applyTimeText[5].ApplyInfo.title6 }}</div>
                    <div class="description">
                        <el-input
                            type="textarea"
                            :placeholder="applyTimeText[5].ApplyInfo.title7"
                            v-model="opusDescribe"
                            show-word-limit
                            class="mytextarea"
                            maxlength="300"
                        >
                        </el-input>
                        <p class="templatebtn">
                            <span>{{ applyTimeText[5].ApplyInfo.title8 }}</span>
                            <b @click="rousedDialog">{{ applyTimeText[5].ApplyInfo.title9 }}</b>
                        </p>
                    </div>
                    <div class="btns">
                        <span class="prev" @click="next(1)">{{ $t('applySteps.backText') }}</span>
                        <span class="next" @click="next(3)">立即申请</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 声明模板 -->
        <el-dialog 
            :title="$t('dialogApplyTimeText.templates.title')" 
            :visible.sync="template" 
            width="40%"
            :modal-append-to-body="false" top="6vh"
            class="tem-dialog"
        >
            <ul>
                <li class="tem-li">
                    <h3>{{ $t('dialogApplyTimeText.templates.title1') }}</h3>
                    <div class="tem-cnt">
                        <p>
                            {{ $t('dialogApplyTimeText.templates.input1') }}
                            <span
                                @click="copy"
                                class="copybtn"
                                :data-clipboard-text="$t('dialogApplyTimeText.templates.input1')"
                            >
                                <i class="el-icon-document-copy"></i>
                            </span>
                        </p>
                    </div>
                </li>
                <li class="tem-li">
                    <h3>{{ $t('dialogApplyTimeText.templates.title2') }}</h3>
                    <div class="tem-cnt">
                        <p>
                            {{ $t('dialogApplyTimeText.templates.input2') }} 
                            <span
                                @click="copy"
                                class="copybtn"
                                :data-clipboard-text="$t('dialogApplyTimeText.templates.input2')"
                            >
                                <i class="el-icon-document-copy"></i>
                            </span>
                        </p>
                    </div>
                </li>
                <li class="tem-li">
                    <h3>{{ $t('dialogApplyTimeText.templates.title3') }}</h3>
                    <div class="tem-cnt">
                        <p>
                            {{ $t('dialogApplyTimeText.templates.input3') }}
                            <span
                                @click="copy"
                                class="copybtn"
                                :data-clipboard-text="$t('dialogApplyTimeText.templates.input3')"
                            >
                                <i class="el-icon-document-copy"></i>
                            </span>
                        </p>
                    </div>
                </li>
                <li class="ts tem-ts">
                    {{ $t('dialogApplyTimeText.templates.bot1') }}
                </li>
            </ul>
        </el-dialog>
        <!-- 第三步确认申请 -->
        <div class="mask_dia" v-if="confirmDia">
            <div class="dia_box">
                <b class="close" @click="(confirmDia=false,stepsActive = 2)"><i class="el-icon-close"></i></b>
                <div class="dia_cnt">
                    <h3>本次认证共计消耗时间戳<b>{{tsCount+aigcData.storeCount}}</b>个，明细如下：</h3>
                    <ul>
                        <li>认证费：<b>{{tsCount}}</b>个时间戳</li>
                        <li v-if="aigcData.storeCount>0">存储费：<b>{{aigcData.storeCount}}</b>个时间戳（视频{{ aigcData.fileSize }}）</li>
                        <li v-else>存储费：免费（3年）</li>
                    </ul>
                    <div class="btns">
                        <el-button type="primary" @click="submit" :loading="applyLoading">确认申请</el-button>
                    </div>
                    <p>具体消耗时间戳标准请参考<a href="/time/Rules" target="_blank">计费规则</a></p>
                </div>
            </div>
        </div>

        <div class="mask_dia" v-if="guideDia">
            <div class="dia_box guide_padding">
                <b class="close" @click="closeGuideDia"><i class="el-icon-close"></i></b>
                <div class="dia_cnt">
                    <h2>操作指引</h2>
                    <p>
                        <b>请在下一步的弹窗中按图中的顺序进行操作</b>（macOS系统需为浏览器分配“录制屏幕”权限）
                    </p>
                    <div class="image_wrap">
                        <img src="@/assets/images/aigc/guide.png" alt="">
                    </div>
                    <div class="checkBox">
                        <el-checkbox v-model="checkedTips">不再提示</el-checkbox>
                    </div>
                    <div class="btns">
                        <el-button type="primary" @click="knowStart">知道了，开始录制</el-button>
                    </div>
                </div>
            </div>
        </div>

        <TsaProgressPopUp
            title="正在申请可信时间戳认证"
            :confirmText="$t('dialogApplyTimeText.confirmCancel.Confirm')"
            :cancelText="$t('dialogApplyTimeText.confirmCancel.Cancel')"
            v-model="ossUploadPopup"
            :fileStatus="{
                waiting: uploadingText.title8,
                processing: uploadingText.title9,
                success:uploadingText.title10,
                fail: uploadingText.title11,
                showResult: uploadingText.title12
            }"
            percentage="ossSliceUploadPercentage"
            success="ossSliceUploadSuccess"
            :width="620"
            :listData="[aigcData]"
            @on-stop="ossUploadPopupStop"
        />
        <TsaDialog class="ProgressPopupCancel"
            v-model="ossUploadProgressPopupCancelShow"
            :width="480"
            :disabled="ossUploadProgressPopupCancelDisabled"
            :confirmText="$t('dialogApplyTimeText.confirmCancel.Confirm')"
            :cancelText="$t('dialogApplyTimeText.confirmCancel.Cancel')"
            @on-confirm="ossUploadProgressPopupConfirm"
            @on-cancel="ossUploadProgressPopupCancel"
        >
            <div class="ProgressPopupCancel-container">
                <div class="title-container">
                <h1>{{ timestampText.tipsText }}</h1>
                <img src="@/assets/images/close.png" @click="ossUploadProgressPopupCancel" alt="">
                </div>
                <span class="el-icon-warning"></span>
                <h2>{{ browserText.info2 }}</h2>
            </div>
        </TsaDialog>

    </div>
</template>

<script>
import MediaRecorder from '@/components/MediaRecorder'
import TsaDialog from '@/components/ApplyTime/TsaDialog'
import TsaProgressPopUp from '@/components/ProgressPopUp'
import {filterParams, generateUUID} from '@/utils/filter'
import {ossMultipartUpload, ossAbortMultipartUpload} from '@/utils/ossMultipartUpload'
import {
  opusUserInfo,
  opusUpdateUserInfo,
  findBalance,
  me,
  getStsInfo,
  findApiApplyResult,
  lengthCount,
  cancelAigcUpload
} from '@/api/service'
var interval = null;
export default {
    data () {
        return {
            guideDia:false,
            checkedTips:false,
            aigcData:{},//最终上传的对象
            stepsActive: 1,
            steps:[
                {
                    index: 1,
                    title: '录制创作过程',
                },
                {
                    index: 2,
                    title: '填写权利信息',
                },
                {
                    index: 3,
                    title: '申请认证',
                },
            ],
            // ****************第一步***************
            hasVideo:false,
            videoBlob:{},
            videoFile:[],
            form1:{
                opusName:'',
            },
            rules:{
                opusName: [
                    { required: true, trigger: 'blur', message: '请输入作品名称' },
                    { min: 1, max: 70, message: '请输入70个字符以内的作品名称', trigger: 'blur' }
                ],
            },
            // ****************第二步***************
            information: {
                applyName: '',
                applyIdType: '',
                applyIdNumber: '',
            },
            opusDescribe: '',//自述声明
            showNameType: '',//当前子账号被父账号设置的显示方式类型
            attestationInfo: {}, //认证信息
            template: false,//自诉模板
            //******************第三步********************
            confirmDia:false,//确认申请弹框
            applyLoading:false,//申请防止连击的loading
            serialNos: [],//固化文件的标识，成功页用到
            ossUploadPopup: false, // 存证 上传到oss的进度弹窗
            ossSliceUploadPercentage: 0, // 分片上传百分比
            ossUploadProgressPopupCancelShow: false,
            ossUploadProgressPopupCancelDisabled:false,
        }
    },
    components: {MediaRecorder,TsaProgressPopUp,TsaDialog},
    computed: {
        applyTimeText() {
            return [
                {
                    ProTypes: this.$t('applyTime.ProTypes.title1'),
                },
                {
                    AutModes: this.$t('applyTime.AutModes'),
                },
                {
                    AddFile: this.$t('applyTime.AddFile'),
                },
                {
                    Next: this.$t('applyTime.Next'),
                },
                {
                    Table: this.$t('applyTime.Table'),
                },
                {
                    ApplyInfo: this.$t('applyTime.ApplyInfo'),
                },
            ]
        },
        applyTimeToast() {
            return this.$t('applyTimeToast')
        },
        balanceTextToast() {
            return this.$t('balanceText')
        },
        confirmCancelToast() {
            return this.$t('confirmCancel')
        },
        applyIdType() {
            switch (this.information.applyIdType) {
                case 101:
                    return this.$t('applySteps.idCard1')
                break
                case 102:
                    return this.$t('applySteps.idCard2')
                break
                case 103:
                    return this.$t('applySteps.idCard3')
                break
                case 104:
                    return this.$t('applySteps.idCard4')
                break
                case 110:
                    return this.$t('applySteps.idCard5')
                break
                case 111:
                    return this.$t('applySteps.idCard6')
                break
                case 109:
                    return this.$t('applySteps.idCard8')
                break
                case 112:
                    return this.$t('applySteps.idCard7')
                break
                case 113:
                    return this.$t('applySteps.idCard9')
                break
            }
        },
        papersType() {
            return [
                {
                    name: this.$t('applySteps.idCard1'),
                    value: 101,
                },
                {
                    name: this.$t('applySteps.idCard2'),
                    value: 102,
                },
                {
                    name: this.$t('applySteps.idCard3'),
                    value: 103,
                },
                {
                    name: this.$t('applySteps.idCard4'),
                    value: 104,
                },
                {
                    name: this.$t('applySteps.idCard5'),
                    value: 110,
                },
                {
                    name: this.$t('applySteps.idCard6'),
                    value: 111,
                },
                {
                    name: this.$t('applySteps.idCard7'),
                    value: 112,
                },
                {
                    name: this.$t('applySteps.idCard8'),
                    value: 109,
                },
                {
                    name: this.$t('applySteps.idCard9'),
                    value: 113,
                },
            ]
        },
        isdisabled() {
            if (this.userInfo.userType == 'AUTHORIZATION_ACCOUNT') {
                if (this.attestationInfo.identificationStatus == 1) {
                    if (this.showNameType == 2) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return true;
                }
            } else {
                if (this.attestationInfo.identificationStatus == 1) {
                    return true;
                }
                return false;
            }
        },
        userInfo: {
            get() {
                return this.$store.state.userInfo.userInfo
            },
            set(val) {
                this.$store.state.userInfo.userInfo = val
            },
            access_token() {
                return this.$store.state.userInfo.me.accessToken
            }
        },
        timestampText() {
            return this.$t('timestampText')
        },
        uploadingText() {
            return this.$t('uploadingText')
        },
        browserText() {
            return this.$t('browserText')
        },
    },
    created() {
        this.form1.opusName=this.$route.query.fileName?decodeURI(this.$route.query.fileName)+'作品_创作过程认证':'';
        this.getUserInfo();
    },
    mounted() {},
    methods: {
        gobuy(){
            window.open(process.env.VUE_APP_ACCOUNTUI + '/userAccount/buy?sourceType=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0))
        },
        keepAlive(){
            // 进行保活处理
            interval = setInterval(() => {
                me()
            }, 60000);
        },
        async getUserInfo() {
            try {
                let res = await opusUserInfo()
                this.attestationInfo = res;
                this.showNameType = res.showNameType;
                this.information = {
                    applyName: res.applyName,
                    applyIdType: res.applyIdType,
                    applyIdNumber: res.applyIdNumber,
                }
            } catch (error) {
                this.$message.error(this.browserText.info7)
            }
        },
        delay(time) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve()
                }, time)
            })
        },
        getFileSize(size) {
            let num = 1024.00; //byte
            if (!size)
                return "";
            if (size < num)
                return size + "B";
            if (size < Math.pow(num, 2))
                return (size / num).toFixed(2) + "K"; //kb
            if (size < Math.pow(num, 3))
                return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
            if (size < Math.pow(num, 4))
                return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
            return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
        },
        getOpenGuide(){
            if(this.$getlocalStorage('closeOpen',31536000000)){
                this.guideDia=false
                this.startRecord()
            }else{
                this.guideDia=true
            }
        },
        closeGuideDia(){
            if(this.checkedTips){
                this.$setlocalStorage('closeOpen',true)
            }
            this.guideDia=false;
        },
        knowStart(){
            this.closeGuideDia();
            this.startRecord();
        },
        startRecord(){
            this.$refs.MediaRecorder.startRecord()
            this.keepAlive();
        },
        handlRecording(){
            this.$refs.MediaRecorder.reStartRecord()
        },
        hadleReset(e){
            this.hasVideo=!e
        },
        async recordingSuccess(blob){
            this.hasVideo=true;
            this.videoBlob=blob;
            clearInterval(interval) // 结束保活
            interval = null // 结束保活
        },
        recordingError(e){
            this.hasVideo=false;
        },
        async next(num,formName){
            let that=this
            if (num == 1) {
                this.stepsActive = 1;
            }else if (num == 2){
                if(!this.form1.opusName){
                    this.$message.error('请输入作品名称')
                };
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(!this.hasVideo){
                            this.$message.error('请先进行视频录制')
                            return
                        }
                        that.videoFile=new File([that.videoBlob],that.form1.opusName+'.webm',{ type: 'video/webm;codecs=vp8' })
                        that.aigcData={
                            fileItem: that.videoFile,
                            opusName: that.videoFile.name,
                            opusDescribe: that.opusDescribe,
                            id: '',
                            uuid: generateUUID(),
                            serialNo: '',
                            isUploadFile:true,
                            ossSliceUploadPercentage: 0, // 分片上传百分比
                            signPdfStart:0,//oss上传完成，开始后台介入,0 未开始  1.开始
                            ossSliceUploadSuccess: 0, // 是否分片上传完成 // 0 未完成， 1 成功， 2 上传失败
                            applyFileSize: that.videoFile.size, // 文件的大小，用来进行存证扣费用的
                            fileSize: that.getFileSize(that.videoFile.size),
                            partnerType : that.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0
                        }
                        that.stepsActive = 2;
                    } 
                })
            }else{
                if (this.showNameType == 2) {
                    if (!this.information.applyName) {
                        this.$message.error(this.applyTimeToast.applicantNameText)
                        return false
                    }
                    if (this.information.applyName.length > 50) {
                        this.$message.error(this.applyTimeToast.applicantText)
                        return false
                    }
                } else {
                    if (!this.information.applyName) {
                        this.$message.error(this.applyTimeToast.applicantNameText)
                        return false
                    }
                    if (this.information.applyName.length > 50) {
                        this.$message.error(this.applyTimeToast.applicantText)
                        return false
                    }
                    if (!this.information.applyIdType) {
                        this.$message.error(this.applyTimeToast.certificateText)
                        return false
                    }
                    if (!this.information.applyIdNumber) {
                        this.$message.error(this.applyTimeToast.idText)
                        return false
                    }
                    if (this.information.applyIdNumber.length < 6 || this.information.applyIdNumber.length > 100) {
                        this.$message.error(this.applyTimeToast.numberIncorrText)
                        return false
                    }
                    let reg = /^[\u4e00-\u9fa5]+$/
                    if (reg.test(this.information.applyIdNumber)) {
                        this.$message.error(this.browserText.info5)
                        return false
                    }
                }
                if (!this.opusDescribe) {
                    this.$message.error(this.applyTimeToast.zishuText)
                    return false
                }
                let obj = {
                    applyName: this.information.applyName,
                    applyIdType: this.information.applyIdType,
                    applyIdNumber: this.information.applyIdNumber,
                }
                if (this.$store.state.userInfo.userInfo.userType !== 'AUTHORIZATION_ACCOUNT' || this.showNameType == 2) {
                    try {
                        await opusUpdateUserInfo(filterParams(obj))
                    } catch (err) {
                        this.$message.error(err.data.message)
                        return false
                    }
                }
                if (this.showNameType == 2) {
                    this.information = filterParams(this.information);
                }
                let res = await lengthCount([{
                    keyType: 0,
                    fileSize: this.aigcData.applyFileSize,
                    key: this.aigcData.uuid,
                }])
                this.tsCount = res.tsCount;
                let resultList = res.resultList;
                let data = resultList.find(i => this.aigcData.uuid == i.key)
                this.aigcData={
                    ...this.aigcData,
                    storeCount: data.storeCount,
                    ...this.information,
                    opusDescribe: this.opusDescribe
                }
                this.confirmDia=true;
                this.stepsActive = 3;
            }
        },
        //*****************第二步*******************
        rousedDialog() {
            this.template = true
        },
        // 获取用户信息
        async getUserInfo() {
            try {
                let res = await opusUserInfo()
                this.attestationInfo = res;
                this.showNameType = res.showNameType;
                this.information = {
                    applyName: res.applyName,
                    applyIdType: res.applyIdType,
                    applyIdNumber: res.applyIdNumber,
                }
            } catch (error) {
                this.$message.error(this.browserText.info7)
            }
        },
        //实名跳转
        goReal() {
            window.open(process.env.VUE_APP_ACCOUNTUI + '/userCenter/AuthenticationName?sourceType=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0))
            this.$alert(this.browserText.info8, this.browserText.info9, {
                confirmButtonText: this.confirmCancelToast.Confirm,
                callback: (action) => {
                    if ((action = 'confirm')) {
                        this.getUserInfo()
                    }
                },
            })
        },
        copy() {
            var clipboard = new this.Clipboard('.copybtn')
            var that = this
            clipboard.on('success', (e) => {
                // console.log('复制成功')
                // 释放内存
                that.$message({
                    message: this.applyTimeToast.copyText,
                    type: 'success',
                })
                setTimeout(function () {
                    that.template = false
                }, 2000)
                clipboard.destroy()
            })
            clipboard.on('error', (e) => {
                // 不支持复制
                that.$message.error(this.browserText.info6)
                // 释放内存
                clipboard.destroy()
            })
        },
        //*******************第三步************************
        async submit(){
            this.applyLoading=true;
            let that = this;
            try {
                let res = await findBalance(this.tsCount+this.aigcData.storeCount);
                if (res.deductible == 1) {  //余额够扣,开始上传
                    await this.handleUpload() // 存证 分片上传
                    let balance = await findBalance(0)
                    that.$store.commit('balance/setBalance', balance)
                    sessionStorage.setItem('serialNos', JSON.stringify(this.serialNos));
                    if (this.serialNos.length > 0) {
                        this.$router.replace({
                            path: '/time/AigcResult',
                        })
                    } else {
                        this.stepsActive = 1;
                        this.confirmDia=false;
                        this.applyLoading=false;
                    }
                } else if (res.deductible == 4) { //身份主账号，余额不足
                    this.$confirm(this.timeStampArrearageMessage(this.balanceTextToast.beforeUseText), this.timestampText.tipsText, {
                        dangerouslyUseHTMLString: true,
                        distinguishCancelAndClose: true,
                        confirmButtonText: this.timestampText.rechargeText,
                        cancelButtonText: this.timestampText.rechargedText,
                        type: 'warning',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                window.open(process.env.VUE_APP_ACCOUNTUI + '/userAccount/buy?sourceType=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0))
                            } else {
                                done()
                            }
                        },
                    }).then(() => {
                        console.log('去充值吧')
                    }).catch(async () => {
                        let balance = await findBalance(0)
                        that.$store.commit('balance/setBalance', balance)
                    })
                }else if (res.deductible == 2) { //身份子账号，主账号余额不足
                    this.$alert(this.timeStampArrearageMessage(this.balanceTextToast.rechargeText), this.timestampText.tipsText, {
                        confirmButtonText: this.confirmCancelToast.Confirm,
                        dangerouslyUseHTMLString: true,
                    });
                } else { //deductible=3 身份子账号，主账号分配次数达上限
                    that.$message.error(this.balanceTextToast.allocateText)
                }
                this.applyLoading=false;
            } catch (error) {
                console.log(error)
                this.applyLoading=false;
                if (error.name && error.name == 'cancel') {
                    console.log('取消存证')
                } else if(error.data.code==300026){
                    this.$message.error(error.data.message)
                }else {
                    this.$message.error(this.browserText.info7)
                }
            }
        },
        //存证过程中，点击取消显示的弹窗
        ossUploadPopupStop() {
            this.ossUploadProgressPopupCancelShow = true
        },
        //开始oss上传
        async handleUpload(){
            return new Promise(async (resolve, reject) => {
                try {
                    // 进行保活处理
                    this.keepAlive();
                    if (this.aigcData.ossSliceUploadSuccess !== 1) {
                        this.aigcData.ossSliceUploadPercentage = 0
                        this.aigcData.signPdfStart=0
                        this.aigcData.ossSliceUploadSuccess = 0
                    }
                    this.ossUploadPopup = true
                    if (this.aigcData.ossSliceUploadSuccess === 0) {
                        let {stsInfoDTO,serialNo,code,message}= await getStsInfo(this.aigcData)
                        if (code === 9523100) { //固化成功
                            this.serialNos=[serialNo];
                            let{accessKey,secretKey,secretToken,bucket,key,storeId}=stsInfoDTO;
                            this.aigcData.storeId=storeId;
                            this.aigcData.serialNo=serialNo;
                            await ossMultipartUpload({
                                accessKey,secretKey,secretToken,bucket,key,
                                file: this.aigcData.fileItem
                            }, ({percentage}) => {
                                this.aigcData.ossSliceUploadPercentage = percentage
                                if(percentage==100){
                                    setTimeout(()=>{
                                        this.aigcData.signPdfStart=1;
                                    },1000)
                                }
                            })
                            let {id} = await this.ossSliceUploadGetStatus({serialNo})
                            if (id) {
                                this.aigcData.ossSliceUploadSuccess = 1 // 上传成功
                                this.aigcData.id = id
                            } else {
                                this.aigcData.ossSliceUploadSuccess = 2 // 上传失败
                            }
                        }else{
                            if (this.solidifyFail(code, this.aigcData, message)) {
                                return
                            }
                        }  
                    }
                    await this.delay(1000) // 1秒后结束固化
                    clearInterval(interval) // 结束保活
                    interval = null // 结束保活
                    this.ossUploadPopup = false
                    this.aigcData.signPdfStart=0;
                    resolve()
                } catch (error) {
                    if (error && error.config) {
                        let {code, message} = error.data
                        if (ExcludeRequest400.indexOf(error.config.url) > -1) {
                            // 存证失败的情况
                            this.existingEvidenceFail(code, this.aigcData, message)
                            return
                        }
                    }
                    reject(error)
                }
            })
            
        },
        // 查询最新的存证状态
        ossSliceUploadGetStatus({serialNo}) {
            return new Promise((resolve, reject) => {
                let time = null;
                clearTimeout(time)
                const getStatus = ({serialNo}) => {
                    findApiApplyResult(serialNo).then(async res => {
                        if (res.signStatus === 4) { // signStatus 0 待扣费 1 扣费失败 2 扣费成功 3 固化中 4 固化成功 5 固化失败
                            resolve(res)
                            clearTimeout(time)
                            time = null
                            let balance = await findBalance(0)
                            this.$store.commit('balance/setBalance', balance)
                        } else {
                            time = setTimeout(() => {
                                getStatus({serialNo})
                            }, 3000)
                        }
                    }).catch((error) => {
                        resolve({})
                        clearTimeout(time)
                        time = null
                    })
                }
                getStatus({serialNo})
            })
        },

        // 固化失败
        async solidifyFail(code, obj, message) {
            obj.ossSliceUploadSuccess = 2;
            if (code === 9523101) { // 固化失败了
                return false
            } else if (code === 10001) {
                if (this.userInfo.userType === 'ADMIN_ACCOUNT') { // 主账号
                    this.$confirm(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
                        dangerouslyUseHTMLString: true,
                        distinguishCancelAndClose: true,
                        confirmButtonText: this.timestampText.rechargeText,
                        cancelButtonText: this.timestampText.rechargedText,
                        type: 'warning',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                window.open(
                                    process.env.VUE_APP_ACCOUNTUI + '/userAccount/buy?sourceType=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0)
                                )
                            } else if (action === 'close') {
                                done()
                            } else {
                                done()
                            }
                        },
                    }).then(() => {
                        console.log('去充值吧')
                    }).catch(async () => {
                        console.log('取消')
                    })
                    this.ossUploadPopup = false
                    this.ossUploadProgressPopupCancelShow = false
                    return true
                } else {
                    this.$alert(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
                        confirmButtonText: this.confirmCancelToast.Confirm,
                        dangerouslyUseHTMLString: true,
                    });
                    this.ossUploadPopup = false
                    this.ossUploadProgressPopupCancelShow = false
                    return true
                }
            } else {
                // 10013 子账号超出最大限额
                this.$alert(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
                    confirmButtonText: this.confirmCancelToast.Confirm,
                    dangerouslyUseHTMLString: true,
                });
                this.ossUploadPopup = false
                this.ossUploadProgressPopupCancelShow = false
                return true
            }
        },
        
        // 存证失败
        async existingEvidenceFail(code, obj, message) {
            // code 2 您的主账号余额不足，请联系主账号充值
            // code 3 您的使用个数已达上限，请联系主账号分配
            // code 4 您的余额不足，请充值后在使用
            message = message || this.browserText.info7
            this.ossUploadPopup = false // 关闭存证进度弹窗
            this.aigcData.signPdfStart=0;
            this.ossUploadProgressPopupCancelShow = false // 关闭结束存证的弹窗（会出现，几率很小）
            obj.ossSliceUploadSuccess = 2
            clearInterval(interval) // 清空保活接口
            interval = null
            switch (code) {
                case 2:
                    this.$alert(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.confirmCancelToast.Confirm,
                    });
                break
                case 3:
                    this.$alert(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.confirmCancelToast.Confirm,
                    });
                break
                case 4:
                    this.$confirm(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
                        dangerouslyUseHTMLString: true,
                        distinguishCancelAndClose: true,
                        confirmButtonText: this.timestampText.rechargeText,
                        cancelButtonText: this.timestampText.rechargedText,
                        type: 'warning',
                        beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            window.open(
                                process.env.VUE_APP_ACCOUNTUI + '/userAccount/buy?sourceType=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0)
                            )
                        } else if (action === 'close') {
                            done()
                        } else {
                            done()
                        }
                        },
                    }).then(() => {
                        console.log('去充值吧')
                    }).catch(async () => {
                        console.log('取消')
                    })
                break
                default:
                    this.$alert(message, this.timestampText.tipsText, {
                        confirmButtonText: this.confirmCancelToast.Confirm,
                    });
            }
        },
        timeStampArrearageMessage(message) {
            return '<div class="timeStampArrearage"><h4>' + this.balanceTextToast.consumeTextLeft1 + (this.tsCount+this.aigcData.storeCount) + this.timestampText.timestamp + '</h4>' + message + '</div>'
        },
        //固化过程中，点击取消
        async ossUploadProgressPopupCancel() {
            // 如果结束固化的过程中存在 hash算完并且 opusApply 还没处理完的情况下
            if ((this.aigcData.ossSliceUploadPercentage === 100 && this.aigcData.ossSliceUploadSuccess === 0) || this.aigcData.ossSliceUploadSuccess === 1) {
                this.$message({
                    message: this.browserText.info10,
                    type: 'warning'
                });
            } else {
                this.ossUploadProgressPopupCancelShow=false;

            }
        },
        //存证过程中，点击结束存证
        async ossUploadProgressPopupConfirm(){
            try {
                this.ossUploadProgressPopupCancelDisabled = true // 禁止重复点击
                let currentObj = this.aigcData;
                if ((currentObj.ossSliceUploadPercentage === 100 && currentObj.ossSliceUploadSuccess === 0)|| currentObj.ossSliceUploadSuccess === 1 ) {
                    this.$message({
                        message: this.browserText.info11,
                        type: 'warning'
                    });
                } else {
                    clearInterval(interval) // 清空保活接口
                    interval = null
                    // 如果上传成功的都不允许删除
                    if (currentObj.ossSliceUploadPercentage < 100) {
                        // 需要调用终止分片的接口 （已经上传到服务器的就不能被取消了）
                        await cancelAigcUpload({
                            serialNo: currentObj.serialNo,
                            storeId: currentObj.storeId,
                        })
                        await ossAbortMultipartUpload() // 取消oss请求
                    }
                    this.$store.commit('axiosCancel/clearAxiosPromiseCancelArr')
                    this.ossUploadPopup = false
                    this.ossUploadProgressPopupCancelShow = false
                }
                this.ossUploadProgressPopupCancelDisabled = false // 禁止重复点击
            } catch (error) {
                if (error && error.status === 400 && error.data && error.data.code == -1 && error.config && error.config.url === '/aigc/cancelStsUpload') {
                    let {id} = await this.ossSliceUploadGetStatus({serialNo:currentObj.serialNo})
                    if (id) {
                        currentObj.ossSliceUploadSuccess = 1 // 上传成功
                        currentObj.id = id
                        currentObj.ossSliceUploadPercentage = 100 // 成功
                    }
                    let balance = await findBalance(0)
                    that.$store.commit('balance/setBalance', balance)
                    this.serialNos=[currentObj.serialNo];
                    sessionStorage.setItem('serialNos', JSON.stringify(this.serialNos));
                    this.$router.replace({
                        path: '/time/AigcResult'
                    })
                } else {
                    await ossAbortMultipartUpload() // 取消oss请求
                    clearInterval(interval) // 清空保活接口
                    interval = null
                    this.allowExistingEvidence = false // 存证不允许
                    this.ossUploadProgressPopupCancelDisabled = false // 禁止重复点击
                    this.$store.commit('axiosCancel/clearAxiosPromiseCancelArr')
                    this.ossUploadPopup = false
                    this.ossUploadProgressPopupCancelShow = false
                }
            }
        },
    },
}

</script>
<style src="../../assets/css/applyTime.less" scoped lang="less"></style>
<style scoped lang="less">
.ProgressPopupCancel {
  :deep(.content) {
    padding: 0;
    padding-bottom: 10px;

    .area {
      padding-bottom: 10px;
    }

    .btnGroup {
      padding-right: 16px;
    }
  }

  .ProgressPopupCancel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;

    .title-container {
      width: 100%;
      display: flex;
      padding: 16px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #F0F0F0;

      h1 {
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-bottom: 0;
      }

      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .el-icon-warning {
      display: block;
      font-size: 50px;
      color: rgb(244, 125, 6);
      margin-top: 47px;
      margin-bottom: 20px;
    }

    h2 {
      text-align: center;
      color: #333333;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 52px;
    }
  }

}

.timeStampArrearage {
  text-align: center;
}

/deep/.form-element-ui{
    .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
        content: '*';
        position: static;
        background-color: transparent;
        width: auto;
        height: auto;
        display: inline;
    }
}
</style>