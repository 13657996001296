<template>
    <div class="tsa-mediaRecorder">
        <div class="prepare card" v-show="status === 0">
            <p>说明: {{ tip }}</p>
            <button @click="sendStart"><span>开始录制</span></button>
        </div>
        <div class="record card" v-show="status === 1">
            <h4>已开始屏幕录制</h4>
            <h2>{{ timing }}</h2>
            <p>系统正在录制你的全部创作过程，<b>创作过程中切勿关闭本页面</b>。<br>
                创作完成后请返回本页面点击“结束录制”</p>
            <button @click="stopRecordDialog"><span>结束录制</span></button>
        </div>
        <div class="preview card" v-show="status === 2">
            <video :src="videoUrl" id='video' controls='true' controlsList='nodownload'></video>
        </div>
        <!-- 浏览器版本弹窗 -->
        <TsaMediaRecorderDialog v-model="versionShow" :message="versionMessage" @on-confirm="versionConfirm"/>
         <!-- 结束录制弹窗 -->
         <TsaMediaRecorderDialog v-model="stopRecordShow" message="确定结束录制？" @on-confirm="stopRecordConfirm"/>
         <!-- 重新录制弹窗 -->
         <TsaMediaRecorderDialog v-model="reStartRecordShow" message="确定要重新录制吗？当前的视频将被删除" @on-confirm="reStartConfirm"/>
    </div>
</template>

<script>
// 不需要被劫持
let time = null
let recorder = null
var video = null
let captureStream = null
import TsaMediaRecorderDialog from './components/dialog/index.vue'
import fixWebmDuration from 'webm-duration-fix'
export default {
    name: 'tsa-mediaRecorder',
    components: {
        TsaMediaRecorderDialog
    },
    data () {
        return {
            versionShow: false,
            versionMessage: '',
            stopRecordShow: false,
            reStartRecordShow:false,
            status: 0, // 0 准备 , 1 录制 , 2 预览
            timing: '00:00:00',
            videoUrl: '',
            tip: '本功能仅支持Chrome浏览器110版本以上，或使用Chrome 110以上核心的其他浏览器，如Edge、360极速浏览器'
        }
    },
    mounted () {
        video = document.getElementById('video')
    },
    methods: {
        //通知父组件录制视频
        sendStart(){
            this.$emit('on-start')
        },
        // 录制视频
        async startRecord () {
            let { browserName, browserVersion } = this.getBrowserInfo()
            console.log({
                browserName,
                browserVersion,
                browserVersion1: parseFloat(browserVersion)
            })
            if ((browserName === 'Chrome'|| browserName==='Edge') && parseFloat(browserVersion) > 110) {
                try{
                    // 开始录屏
                    captureStream = await navigator.mediaDevices.getDisplayMedia({
                        video: { displaySurface: "monitor" },
                        audio: {
                            autoGainControl: false,
                            echoCancellation: false,
                            noiseSuppression: false
                        }
                    })
                    let mime = MediaRecorder.isTypeSupported('video/webm; codecs=vp8') ? 'video/webm; codecs=vp8' : 'video/webm';
                    this.status = 1
                    // new 一个媒体记录
                    recorder = new MediaRecorder(captureStream,{mimeType: mime})
                    recorder.start()
                    
                    captureStream.getVideoTracks()[0].onended = () => {
                        // 录屏结束完成
                        recorder.stop()
                    }
                    let currentTime = new Date().getTime()
                    time = setInterval(() => {
                        this.timing = this.timeFormat(parseInt((new Date().getTime()-currentTime)/1000))
                    }, 1000)
                    var chunks = [];
                    recorder.addEventListener('dataavailable', function(e) {
                        console.log(e)
                        console.log(e.data)
                        chunks.push(e.data)
                    })
                    // 停止
                    recorder.addEventListener('stop', async () => {
                        captureStream = null
                        var blob =await fixWebmDuration(new Blob(chunks, {type: chunks[0].type})) 
                        this.videoUrl = URL.createObjectURL(blob, {type: 'video/ogg'})
                        this.$emit('on-success', blob)
                        this.status = 2
                    })
                } catch (e){
                    // 取消录屏或者报错
                    this.$emit('on-error', e.message)
                    return
                }
            } else {
                this.versionShow = true
                if(browserName !== 'Chrome' && browserName !== 'Edge'){
                    this.versionMessage = '<h4>暂不支持你使用的浏览器</h4>\
                                <div class="info"><p>你的浏览器为：xxxxx</p>\
                                <p>' + this.tip + '</p></div>'
                }else if((browserName === 'Chrome'|| browserName==='Edge') && parseFloat(browserVersion) < 110 ){
                    this.versionMessage = '<h4>暂不支持你使用的浏览器版本，请升级浏览器</h4>\
                                <div class="info"><p>你的浏览器为：xxxxx</p>\
                                <p>' + this.tip + '</p></div>'
                }else{
                    this.versionMessage = '<h4>暂不支持你使用的浏览器版本</h4>\
                                <div class="info"><p>你的浏览器为：xxxxx</p>\
                                <p>' + this.tip + '</p></div>'
                }
                this.versionMessage = this.versionMessage.replace('xxxxx', browserName + ' ' + browserVersion )
            }
        },
        stopRecordDialog () {
            this.stopRecordShow = true
        },
        // 结束录制
        stopRecord () {
            if (captureStream) {
                let tracks = captureStream.getTracks()
                tracks.forEach(track => track.stop())
            }
            recorder && recorder.stop()
            clearInterval(time)
            time = null
        },
        reStartConfirm(){
            this.reStartRecordShow=false
            this.resetStatus()
            this.$emit('on-reset',true)//通知父级以重新录制
        },
        reStartRecord () {
            this.reStartRecordShow=true
        },
        // 重置状态
        resetStatus () {
            recorder = null
            video = null
            captureStream = null
            // 删除之前的 Blob
            window.URL.revokeObjectURL(this.videoUrl)
            clearInterval(time)
            this.status = 0
            this.videoUrl = ''
            this.timing = '00:00:00'
        },
        // 格式化时间
        timeFormat (seconds) {
            let hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
            seconds -= 3600 * hour;
            let min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
            seconds -= 60 * min;
            let sec = seconds >= 10 ? seconds : '0' + seconds;
            return hour + ':' + min + ':' + sec;
        },
        // 获取浏览器版本
        getBrowserInfo() {
            const userAgent = navigator.userAgent.toLowerCase()
            console.log(userAgent)
            // 检查userAgent以确定浏览器类型
            const isIE = userAgent.includes('msie') || userAgent.includes('trident')
            const isEdge = userAgent.includes('edg/')
            const isFirefox = userAgent.includes('firefox/')
            const isChrome = userAgent.includes('chrome/')
            const isSafari = userAgent.includes('safari/') && !isChrome

            // 从userAgent中提取版本号
            const version = (
                (isIE && userAgent.match(/(msie|rv:)\s?([\d.]+)/)?.[2]) ||
                (isEdge && userAgent.match(/edg\/([\d.]+)/)?.[1]) ||
                (isFirefox && userAgent.match(/firefox\/([\d.]+)/)?.[1]) ||
                (isChrome && userAgent.match(/chrome\/([\d.]+)/)?.[1]) ||
                (isSafari && userAgent.match(/version\/([\d.]+)/)?.[1]) ||
                ''
            )

            // 根据浏览器类型返回名称
            const name = (
                (isIE && 'ie') ||
                (isEdge && 'Edge') ||
                (isFirefox && 'Firefox') ||
                (isChrome && 'Chrome') ||
                (isSafari && 'Safari') ||
                ''
            )

            // 返回包含浏览器名称和版本号的对象
            return {
                browserName: name,
                browserVersion: version
            }
        },
        versionConfirm () {
            this.versionShow = false
        },
        stopRecordConfirm () {
            this.stopRecordShow = false
            this.stopRecord()
        },
    },
    beforeDestroy () {
        this.stopRecord()
        // 重置所有状态
        this.resetStatus()
    }
}
</script>

<style scoped lang="less">
.tsa-mediaRecorder {
    * {
        margin: 0;
        padding: 0;
    }
    .card {
        border-radius: 20px;
        border: 3px solid #E3E8FF;
    }
    button {
        border-radius: 57px;
        border: 2px solid #E3E8FF;
        color: #8B9EFF;
        font-size: 20px;
        padding: 4px 22px;
        background: #ffffff;
        cursor: pointer;
        &:hover {
            color: #ffffff;
            background: #8B9EFF;
        }
    }
    .prepare {
        width: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FBFBFB;
        p {
            color: #1F5EFF;
            font-weight: bold;
            font-size: 12px;
            margin-top: 36px;
            margin-bottom: 21px;
        }
        button {
            margin-bottom: 16px;
        }
    }
    .record {
        width: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
            font-size: 16px;
            color: #1F5EFF;
            font-weight: bold;
            margin-top: 36px;
            margin-bottom: 40px;
        }
        h2 {
            font-size: 36px;
            font-weight: bold;
            color: #FF3333;
            line-height: 42px;
            margin-bottom: 8px;
        }
        p {
            width: 500px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #999999;
            line-height: 20px;
            margin-bottom: 36px;
            b {
                color: #FF9900;
            }
        }
        button {
            margin-bottom: 52px;
            background: #FF3333;
            border-color: #FF3333;
            color: #ffffff;
            box-shadow: 0 0 2px #999;
            &:hover {
                color: #FF3333;
                background: #ffffff;
            }
        }
    }
    .preview {
        width: 800px;
        height: 402px;
        border: 0!important;
        overflow: hidden;
        video {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
</style>