<template>
    <div class="tsa-mediaRecorder-dialog" v-show="show">
        <div class="content">
            <div class="close">
                <img src="../../images//close.png" alt="" @click="close">
            </div>
            <h2>{{ title }}</h2>
            <div v-html="message" class="message"></div>
            <div class="btnGroup">
                <a href="javascript:;" @click="close"><span>{{ cancelText }}</span></a>
                <a href="javascript:;" @click="confirm"><span>{{ confirmText }}</span></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tsa-mediaRecorder-dialog',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '提示'
        },
        message: {
            type: String,
            default: ''
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        confirmText: {
            type: String,
            default: '确定'
        }
    },
    data () {
        return {
            show: false
        }
    },
    watch: {
        value (val) {
            this.show = val
        }
    },
    methods: {
        confirm () {
            this.$emit('on-confirm')
        },
        close () {
            this.$emit('input', false)
        }
    }
}
</script>

<style lang="less" scoped>
.tsa-mediaRecorder-dialog {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        width: 600px;
        padding: 20px;
        padding-bottom: 30px;
        border-radius: 20px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .close {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            img {
                width: 30px;
                height: autos;
                cursor: pointer;
            }
        }
        h2 {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 20px;
        }
        /deep/.message {
            font-size: 24px;
            color: #666666;
            line-height: 28px;
            margin-bottom: 40px;
            h4 {
                font-weight: normal;
                text-align: center;
                font-size: 20px;
                color: #333;
                margin-bottom: 20px;
            }
            .info {
                padding: 0 40px;
                p {
                    font-size: 16px;
                }
            }
            
        }
        .btnGroup {
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                display: block;
                width: 128px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid #E3E8FF;
                border-radius: 69px;
                text-decoration: none;
                span {
                    color: #8B9EFF;
                    font-size: 20px;
                    letter-spacing: 2px;
                }
                &:first-child {
                    margin-right: 8px;
                    span {
                        padding-left: 10px;
                    }
                }
                &:last-child {
                    span {
                    }
                }
                &:hover {
                    background: #8B9EFF;
                    span {
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>